import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import { graphql } from 'gatsby'
import { Link } from 'gatsby'


import { isLoggedIn, logout } from '../services/auth'

import Amplify from 'aws-amplify'
import awsmobile from '../aws-exports'
Amplify.configure(awsmobile)

class WorkPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  openModal(e) {
    this.setState({ modal: e })
    document.getElementById("modal").style.display = "block"
  }

  closeModal() {
    document.getElementById("modal").style.display = "none"
  }

  render() {
    let workData = this.props.data.allWorkJson.nodes[0]
    let workItems = []
    let i = 0
    workData.work_items.forEach(item => {
      workItems.push(
	    <div>
	    <p className="text pt-12">{item.name}</p>
        <div
          key={i}
          onClick={this.openModal.bind(this, item)}
          className={
            this.state.smallScreen ? "grid-item-small" : "work-grid-item"
          }
          style={{
            backgroundImage: `url(${item.image})`,
            backgroundSize: "100%",
          }}
        ></div></div>
      )
      i++
    })
    return (
      <Layout page={"work"}>
        <h1 className="title p-3">{workData.title}</h1>
        <div className="text">{workData.text}</div>
        
        	{isLoggedIn() ? (	
	          <div><center><Link to="/app/home"><button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Ga naar de lessenreeks</button></Link></center></div>
			  ) : (
	          <div><center><Link to="/"><button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Bestel de lessenreeks</button></Link></center></div>
			  )}
        
        <div
          className={
            this.state.smallScreen
              ? "grid-container-small"
              : "work-grid-container"
          }
        >
        <center>
          {workItems}
        </center>
        </div>
        <div id="modal" className="modal" onClick={this.closeModal}>
          <div
            className={
              this.state.smallScreen ? "modal-content-small" : "modal-content"
            }
          >
            <span className="modal-close">&times;</span>
            <div className="modal-grid-container">
              	<img
                  src={this.state.modal.image}
                  alt={this.state.modal.name}
                  className="modal-image ml-8"
                >
                </img>
                <p></p>
                <div><h2 className="modal-title">{this.state.modal.name}</h2></div>
                <p></p>
                <p className="modal-text"><div dangerouslySetInnerHTML={{__html:this.state.modal.description}} /></p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default WorkPage

export const query = graphql`
  query {
    allWorkJson {
      nodes {
        id
        title
        description
        text
        work_items {
          name
          description
          completed
          image
        }
      }
    }
  }
`
